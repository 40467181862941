import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
// import { FiArrowRight } from 'react-icons/fi'
import { TiSocialLinkedin } from 'react-icons/ti';
import Img1 from '../../images/team/1.jpeg'
import Img2 from '../../images/team/2.jpeg'
import Img3 from '../../images/team/3.jpeg'
import Img4 from '../../images/team/4.jpeg'
import Img5 from '../../images/team/5.jpeg'
import Img7 from '../../images/team/7.jpeg'
import Img8 from '../../images/team/8.jpeg'
import Img9 from '../../images/team/9.jpg'
import Img10 from '../../images/team/10.jpg'

import BlogForm from './Blog/BlogForm'




const Team = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "team_banner1.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/team/">
                        <div className="banner_section">
                            <Img
                                Tag="section"
                                fluid={imageData}
                                className="startup_background"
                            ></Img>
                        </div>
                        <section className="startup_section">
                            <Container>
                                <Row>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href>
                                                    <img src={Img7} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Sirus</h5>
                                            <div className="title">  Business Development Executive</div>
                                            <p>
                                                Sirus attended University of California, Davis to pursue a degree in Managerial Economics. While he was there, he was the Asst. Director of Basketball Operations for the women's D1 basketball team and simultaneously a student-athlete for the Men's Rugby team. Sirus is able to bring his management and communication skills to our clients to ensure that we provide the best possible service and build relationships with our clients. In his free time Sirus enjoys fitness, sports and spending time with his dog.
                                        {/* Highly motivated and passionate HR Manager who is self-motivated and enthusiastic in her work. */}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href>
                                                    <img src={Img8} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Neetu</h5>
                                            <div className="title"> HR Manager<br /></div>
                                            <p>Highly motivated and passionate HR Manager,  Neetu has a positive attitude, a strong work ethic, and a keen desire to learn and grow within the firm.
                                                Neetu manages all the day-to-day HR responsibilities, including Payroll, Onboarding, Off-boarding, negotiating offers, vendor management.
                                                She administers the immigration process(H1-B, etc,) and doesn’t bow to pressure.
                                                A multitasker at work and beyond, she loves drawing up contracts as much as Bollywood dancing.
                                                She is certified for HIPPA compliance
                                            {/* I started Trident’s staffing practice in the Philippines, as a Recruitment manager. While I worked there, I learnt a lot about the US staffing/ recruitment industry. It also gave me an opportunity to work in a pleasant, multicultural environment. Trident has always been people centric and has excellent core values. I would highly recommend Trident as an employer. */}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href>
                                                    <img src={Img9} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Saravanan C</h5>
                                            <div className="title">Sr. Manager<br /></div>
                                            <p>“Saravanan is a self-motivated and detailed oriented person who is energetic and enthusiastic with over 10 years of hands on experience in US Staffing. He possesses a strong track record of successfully building competent teams by motivating them to  produce the desired results. He is team player and is equally loved  both by his colleagues and clients.
                                                During his free time - Saran likes to travel, play badminton, spend time with family and loves to watch movies.
                                        </p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href="https://www.linkedin.com/in/somasundarsrinivasan/" target="blank">
                                                    <img src={Img1} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Somasundar Srinivasan</h5>
                                            <div className="title"> Sr. Delivery Manager</div>
                                            <p>A keen planner and strategist with proven track records of consistently increasing the sales/ profitability of the company. His experience includes People, Account and vendor management/ Full recruitment life cycle implementation/ Salary/Rate negotiations, Contract negotiations with third parties/New and Existing Business development/lead generation/Networking and headhunting. He is a self-driven personality coupled with a problem-solving attitude and a positive attitude towards peers, superiors, and subordinates.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href="https://www.linkedin.com/in/sridhar-ramalingam-0b26a4b2/" target="blank">
                                                    <img src={Img2} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Sridhar Ramalingam</h5>
                                            <div className="title">Recruitment Manager</div>
                                            <p>An accomplished Human Resources and Staffing Professional with 6 years of experience in U.S IT staffing & Consulting, Business Partnership, and Key Account Management. He is a goal-oriented individual with strong leadership capabilities. He is well organized, motivated, and detail directed problem solver. He mentoring & motivating the team members to understand the client requirements and helping them in sourcing activities to ensure everyone meets the management expectations.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href="https://www.linkedin.com/in/mahendra-kumar-250260b5/" target="blank">
                                                    <img src={Img3} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Mahendra Kumar</h5>
                                            <div className="title"> Delivery – Resource Manager</div>
                                            <p>“I feel very proud to be one of the oldest employees of Trident. My career has skied with the growth of the company. I am really grateful to work in a place which makes us grow to greater heights.” “Trident provides us a conducive and employee-friendly work environment, which helps the employee to give quality work to the clients. In terms of career growth also it provides the best platform to enhance knowledge and skills “-Lots of opportunities are provided to explore on their own grounds.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href="https://www.linkedin.com/in/osborn-rufus-34a66395/" target="blank">
                                                    <img src={Img4} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5>Osborn Rufus</h5>
                                            <div className="title"> Operation Manager</div>
                                            <p>Osborn heads Trident’s Tirunelveli branch and manages all recruiting and operations there. He oversees the recruitment teams and is responsible for hiring, resource management, budgeting, infrastructure, and planning. Also, he has a great passion for cars and he really loves his brand new ŠKODA When Osborn is not working, he enjoys spending time with his wife, a loving daughter and a dog called Dora.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href="https://www.linkedin.com/in/maggie-m-89b69aaa/" target="blank">
                                                    <img src={Img5} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5> Maggie</h5>
                                            <div className="title"> Sr. Technical Recruiter</div>
                                            <p>Has over 5 years of experience in full life cycle recruitment. She is good at developing & maintaining strong vendor and candidate relationships. She is such a Self-motivated professional who achieves results and has a superior ability to coordinate and perform several tasks simultaneously.</p>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className="team">
                                            <div className="team_img">
                                                <a href>
                                                    <img src={Img10} alt="img" />
                                                    <div className="overlay" />
                                                    <div className="social">
                                                        <TiSocialLinkedin color={'#fff'} size={35} />
                                                    </div>
                                                </a>
                                            </div>
                                            <h5> Janani Suresh</h5>
                                            <div className="title">Account Manager</div>
                                            <p>Janani is an accomplished, self-motivated and detailed oriented professional having 7 years of experience in US IT Staffing. Being passionate about finding great people and finding them great careers. A data-driven and insightful recruitment professional who is passionate about growing teams and dreams. During her free time Janani likes to do glass painting, travelling, cooking and listening to music.</p>
                                        </div>
                                    </Col>
                                    {/* <Col md="4">
                                    <div className="team">
                                        <div className="team_img">
                                            <a href="https://www.linkedin.com/in/shajoe/" target="blank">
                                                <img src={Img6} alt="img" />
                                                <div className="overlay" />
                                                <div className="social">
                                                    <TiSocialLinkedin color={'#fff'} size={35} />
                                                </div>
                                            </a>
                                        </div>
                                        <h5> Shajoe JB</h5>
                                        <div className="title">Business HR</div>
                                        <p> A well-disciplined, passionate and motivated individual who is able to engage, interact and Consistently deliver while being dedicated to attaining organizational goals. HR Professional who is a People Lover, Energetic, Articulate, Diplomatic, Tolerant, Reliable, Charismatic & Altruistic. He is responsible to build and maintain strong, objective and valued relationships with all levels of the business, including key managers and consulting staff.</p>
                                    </div>
                                </Col> */}

                                </Row>
                            </Container>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find out what Trident can do for you.</h1>
                                        <div className="footer_button">
                                            <a href onClick={toggle}>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                                            </ModalBody>
                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                        {/* <BlogForm refItem={() => { }} title="Find out what Trident can do for you." redirectTitle="Team" pathname={props.location.pathname} /> */}

                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Team)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection